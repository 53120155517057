<template>
    <div>
        <multiselect v-model="inputVal.value"
                     :searchable="true"
                     :options="Object.keys(getCustomerTags())"
                     :multiple="inputVal.condition=='in'"
                     :custom-label="opt => getCustomerTags()[opt]"
        ></multiselect>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    props: {
        'value': {},
        'labelPrefix': {},
    },
    methods: {
        ...mapGetters('CommonData', ['getCustomerTags']),
    },
    mounted() {
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
    }
}
</script>